// ** React Imports
import { createContext, useEffect, useState } from 'react'

// ** Next Import

// ** Axios Imports
import axiosInstance from 'src/store/components/axiosInstance'

// ** Config
import authConfig from 'src/configs/auth'
import { useNavigate } from 'react-router-dom'

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
}
const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  const [accessToken, setAccessToken] = useState('')
  const [loading, setLoading] = useState(defaultProvider.loading)

  // ** Hooks
  const navigate = useNavigate()

  useEffect(() => {
    const storedToken = window.localStorage.getItem(authConfig.storageTokenKeyName)
    const userData = window.localStorage.getItem('userData')

    if (!userData || !storedToken) {
      navigate('/login')
    }

    setAccessToken(storedToken)
    setUser(JSON.parse(userData))

    let isRefreshing = false // Track if token refresh is in progress
    let refreshSubscribers = [] // Queue of failed requests waiting for the token refresh

    // Function to subscribe to the refresh process (push a request to the queue)
    const subscribeTokenRefresh = cb => {
      refreshSubscribers.push(cb)
    }

    // Function to notify all subscribers when a new token is available
    const onRrefreshed = newToken => {
      refreshSubscribers.forEach(cb => cb(newToken))
      refreshSubscribers = [] // Clear the queue after all subscribers are resolved
    }

    // Set up Axios interceptor (Add the interceptor)
    const interceptor = axiosInstance.interceptors.response.use(
      response => response, // If the response is successful, just return the response
      async error => {
        const originalRequest = error.config // Save the original request

        // Check for 401 unauthenticated error
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true // Mark the request as retried

          if (!isRefreshing) {
            isRefreshing = true // Start token refresh process

            try {
              const refreshToken = window.localStorage.getItem('refreshToken')
              const { data } = await axiosInstance.post(authConfig.refreshEndpoint, { refresh_token: refreshToken })

              // Update the stored tokens with new ones
              window.localStorage.setItem(authConfig.storageTokenKeyName, data.data.accessToken)
              window.localStorage.setItem('refreshToken', data.data.refreshToken)

              setAccessToken(data.data.accessToken)

              // Notify all subscribers (queued requests) with the new token
              onRrefreshed(data.data.accessToken)

              isRefreshing = false // Reset the refresh flag

              // Retry the original request with the new token
              originalRequest.headers['Authorization'] = `Bearer ${data.data.accessToken}`
              return axiosInstance(originalRequest)
            } catch (refreshError) {
              // If refreshing the token fails, log out the user
              handleLogout()
              return Promise.reject(refreshError)
            }
          } else {
            // If a token refresh is already in progress, queue the request
            return new Promise(resolve => {
              subscribeTokenRefresh(newToken => {
                // Retry the original request with the new token once it's available
                originalRequest.headers['Authorization'] = `Bearer ${newToken}`
                resolve(axiosInstance(originalRequest))
              })
            })
          }
        }

        // If the error is not a 401 or the request has already been retried, reject the promise with the error
        return Promise.reject(error)
      }
    )

    // Cleanup function to eject the interceptor when the component unmounts
    return () => {
      axiosInstance.interceptors.response.eject(interceptor)
    }
  }, [])

  const handleLogin = (params, errorCallback) => {
    axiosInstance
      .post(authConfig.loginEndpoint, params)
      .then(async response => {
        const { accessToken, refreshToken, userData } = response.data.data
        if (params.rememberMe) {
          window.localStorage.setItem(authConfig.storageTokenKeyName, accessToken)
          window.localStorage.setItem('refreshToken', refreshToken)
          window.localStorage.setItem('userData', JSON.stringify(userData))
        }
        setUser(response.data.data.userData)
        setAccessToken(response.data.data.accessToken)
        params.rememberMe ? window.localStorage.setItem('userData', JSON.stringify(response.data.data.userData)) : null
        navigate('/home')
      })
      .catch(err => {
        if (errorCallback) errorCallback(err)
      })
  }

  const handleLogout = () => {
    setUser(null)
    window.localStorage.removeItem('userData')
    window.localStorage.removeItem(authConfig.storageTokenKeyName)
    navigate('/login')
  }

  const values = {
    user,
    accessToken,
    loading,
    setUser,
    setLoading,
    login: handleLogin,
    logout: handleLogout
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
